import Contact from "@/entity/Contact";
import { Location } from "vue-router";

export default (contact: Contact): Location | null => {
  if (contact === undefined || contact.id === undefined) {
    return null;
  }
  if (contact.id) {
    return {
      name: "contactDetail",
      params: {
        contactId: contact.id.toString(),
      },
    };
  }
  return null;
};
